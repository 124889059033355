import httpV2 from '@/utils/httpV2';
import dictUtil from '@/utils/dictionaryUtil';
const state = {

};
const getters = {};

const mutations = {};

const actions = {
    async getDict({ state, commit }, dictName) {
        let list = await httpV2.get(process.env.VUE_APP_SDP + '/v1.0/dicts/'+dictName+'/items', {}, this);
        state[dictName]=list;
        dictUtil.setDict(dictName, list);
    },
    /*
    * 设置字典到common的state  项目中有使用
    * this.$store.state.common.lang.platform
    * this.$store.state.common.lang.countrys
    * 方法获取字典
    * */
    setStateDict(code, data){
        if('lang' === code || 'countrys' === code || 'platform' === code ){
            state[code] = data
        }else {
        }
    }
};
export default {
    getters,
    state,
    mutations,
    actions
};
