import httpV2 from '@/utils/httpV2';

const state = {};
const getters = {};

const mutations = {};

const actions = {

    //获取锁定时间
    getLeftLockTime({ state, commit }, params) {
        return httpV2.get2('/arc/v1.0/fullSync/getLeftLockTime', params, this);
    },
    //发送全量同步消息
    sendFullSyncMsg({ state, commit }, params) {
        return httpV2.get2('/arc/v1.0/fullSync/sendFullSyncMsg', params, this);
    },

};





export default {
    getters,
    state,
    mutations,
    actions
};