import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n';
import { messages } from './components/common/i18n';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
// import './assets/css/theme-green/index.css'; // 浅绿色主题
import './assets/css/icon.css';
import './components/common/directives';
import 'babel-polyfill';
import Config from '@/utils/config';
import Cache from '@/utils/cache';

//import oidcVarible from '@/api/oidc_varible.js'
import oidcVarible from '@/store/oidc_varible.js';
import common from '@/utils/common';
import dict from '@/utils/dictionaryUtil';
import * as filters from '@/filters/index';
import './assets/icon/iconfont.css';

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

Vue.prototype.oidcVarible = oidcVarible;

Vue.prototype.$common = common;
Vue.prototype.$dict = dict;

Vue.config.productionTip = false;

Vue.use(mavonEditor)
Vue.use(VueI18n);
Vue.use(ElementUI, {
    size: 'small'
});
const i18n = new VueI18n({
    locale: 'zh',
    messages
});

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
    // 验证拦截是否有用户信息
    if (to.name != 'login' && to.name != 'signin-oidc' && to.name != 'signin') {
        oidcVarible.mgr.getUser().then(function(user) {
            if (user) {
                Cache.set(Config.userInfo, user.profile);
                next();
            } else {
                Cache.setSession('query', to.query);

                window.location = '/';
            }
        });
    } else {
        next();
    }
});

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');

/**
 * 生成随机文件名
 * @param len
 * @returns {string}
 */
Vue.prototype.randomString = function(len) {
    len = len || 32;
    let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    let maxPos = chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
};
/**
 * 获取文件后缀名
 * @param filename
 * @returns {string}
 */
Vue.prototype.getSuffix = function(filename) {
    let pos = filename.lastIndexOf('.');
    let suffix = '';
    if (pos != -1) {
        suffix = filename.substring(pos);
    }
    return suffix;
};
