import Vue from 'vue'
import Vuex from 'vuex'
import dictionary from './dictionary'
import api from './api'
import banner from './banner'
import goods from './goods'
import common from './common'
import worker from './worker'
import activity from './activity'
import users from './users'
import goodsClassSet from './goodsClassSet'
import strength from '@/store/strength'
import certificate from '@/store/certificate'
import userComment from '@/store/userComment'
import joinUs from '@/store/joinUs'
import homeArticle from '@/store/homeArticle'
import subscribe from '@/store/subscribe'
import countryConfig from '@/store/countryConfig'
import seoConfig from '@/store/seoConfig'
import classAdmin from '@/store/classAdmin'
import informationV2 from '@/store/informationV2'
import keyword from '@/store/keyword'
import falconPlan from '@/store/falconPlan'
import customParameter from '@/store/customParameter'
import userReply from '@/store/userReply'
import sendSyncMessage from './sendSyncMessage'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        dictionary,
        api,
        banner,
        goods,
        common,
        worker,
        activity,
        users,
        goodsClassSet,
        strength,
        certificate,
        userComment,
        joinUs,
        homeArticle,
        subscribe,
        countryConfig,
        seoConfig,
        classAdmin,
        informationV2,
        keyword,
        falconPlan,
        customParameter,
        userReply,
        sendSyncMessage
    }
})