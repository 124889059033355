export default {

    //取出数据
    getStr(key) {
        return localStorage.getItem(key);
    },
    //存储
    setStr(key, value) {
        localStorage.setItem(key, value);
    },
    //存储
    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    //取出数据
    get(key) {
        let obj =  JSON.parse(localStorage.getItem(key))
        return obj;
    },
    // 删除数据
    remove(key) {
        localStorage.removeItem(key);
    },
    //存储
    setSession(key, value) {
        sessionStorage.setItem(key, JSON.stringify(value));
    },
    getSession(key) {
        return JSON.parse(sessionStorage.getItem(key));
    },

}