
import cache from "./cache";

const split="_"
const application_name='ow_admin'
const dict_summary='dictSummary'
const dict_items='dictItems'
const dict_summary_pre=application_name + split + dict_summary
const dict_item_pre=application_name + split + dict_items

function getDictItemsKey(...keys){
    let res = dict_item_pre
    for (let key of keys) {
        res = res + split + key
    }
    return res;
}
function getDictSummaryKey(...keys){
    let res = dict_summary_pre
    for (let key of keys) {
        res = res + split + key
    }
    return res;
}

//存储字典 自动拼接前缀
function setDict(key, value) {
    key = getDictItemsKey(key)
    cache.set(key, value)
}
// 获取字典 自动拼接前缀
function getDict(key) {
    key = getDictItemsKey(key)
    return cache.get(key);
}

export default {
    getDictSummaryKey,
    setDict,
    getDict,
    // 返回SDP字典编码对应的名称
    getDictValue(tag, code) {
        var dict = getDict(tag);
        if (dict == undefined || dict == null || dict.length == 0) {
            return code;
        }
        var code = dict.find(x => x.code == code);
        if (code) {
            return code.name;
        }
    },
}