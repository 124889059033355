import cache from "@/utils/cache";
import Config from "@/utils/config";


function getTenantId() {
    return cache.get(Config.currentTenant) == null ? 1001001000 : cache.get(Config.currentTenant).tenant_id
}

function getLanguageCode() {
    return cache.get(Config.localLangage) == null ? "" : cache.get(config.localLangage).code
}

export default {
    getTenantId, getLanguageCode
}